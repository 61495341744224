<template>
  <v-container
    fill-height
  >
    <v-layout
      align-end
      justify-end
    >
      <v-flex
        xs8
        sm8
        md5
      >
        <v-form>
          <v-card
            class="mx-auto"
            color="#ffffff"
          >
            <v-toolbar
              dark
              color="#baa995"
            >
              <v-toolbar-title
                style="color: white;">
                Login
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                v-model="email"
                name="login"
                label="Login"
              />
              <v-text-field
                v-model="password"
                :type="'password'"
                name="password"
                label="Senha"
              />
              <v-text
                v-if="texto != ''"
                style="color: red; font-size: 14px"
              >
                {{ texto }}
              </v-text>
            </v-card-text>
            <v-divider light />
            <v-card-actions>
              <v-btn
                color="#baa995"
                style="color: black;"
                light
                @click="login()"
              >
                Entrar
              </v-btn>
              <v-btn
                color="#e6d4be"
                light
              >
                Cadastrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'Login',
  data: () => ({
    email: '',
    texto: '',
    password: '',
    visivel: false
  }),
  created(){
    this.validation()
  },
  beforeMount () {
    // console.log(this.$princ.visivel)
    // validation()
  },
  methods: {
    validation(){
      this.$http.get('/vendas/weeksales').then(() => {
        this.$router.push({ name: 'Dashboard' })
      }).catch(() => {
      })
    },
    login () {
      this.$http.post('/authenticate', { email: this.email, senha: this.password })
        .then(request => {
        //  console.log(request.data.user)
          localStorage.setItem('user', JSON.stringify(request.data.user))
          localStorage.setItem('token', request.data.token)
          this.$router.push({ name: 'Dashboard' })
        }).catch(err => {
          if (err.error) {
            this.texto = err.error + '!'
          } else {
            this.texto = 'Verifique a conexão com a internet'
          }
        })
    }
  }
}
</script>
